"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSit = instanceOfSit;
exports.SitFromJSON = SitFromJSON;
exports.SitFromJSONTyped = SitFromJSONTyped;
exports.SitToJSON = SitToJSON;
/**
 * Check if a given object implements the Sit interface.
 */
function instanceOfSit(value) {
    if (!('access_level' in value) || value['access_level'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('subscription_id' in value) || value['subscription_id'] === undefined)
        return false;
    return true;
}
function SitFromJSON(json) {
    return SitFromJSONTyped(json, false);
}
function SitFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'access_level': json['access_level'],
        'id': json['id'],
        'professional_id': json['professional_id'],
        'subscription_id': json['subscription_id'],
    };
}
function SitToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'access_level': value['access_level'],
        'id': value['id'],
        'professional_id': value['professional_id'],
        'subscription_id': value['subscription_id'],
    };
}
