"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMe = instanceOfMe;
exports.MeFromJSON = MeFromJSON;
exports.MeFromJSONTyped = MeFromJSONTyped;
exports.MeToJSON = MeToJSON;
var User_1 = require("./User");
var Professional_1 = require("./Professional");
var WorkspaceWithCustomer_1 = require("./WorkspaceWithCustomer");
/**
 * Check if a given object implements the Me interface.
 */
function instanceOfMe(value) {
    if (!('professional' in value) || value['professional'] === undefined)
        return false;
    if (!('user' in value) || value['user'] === undefined)
        return false;
    if (!('workspaces' in value) || value['workspaces'] === undefined)
        return false;
    return true;
}
function MeFromJSON(json) {
    return MeFromJSONTyped(json, false);
}
function MeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'professional': (0, Professional_1.ProfessionalFromJSON)(json['professional']),
        'user': (0, User_1.UserFromJSON)(json['user']),
        'workspaces': (json['workspaces'].map(WorkspaceWithCustomer_1.WorkspaceWithCustomerFromJSON)),
    };
}
function MeToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'professional': (0, Professional_1.ProfessionalToJSON)(value['professional']),
        'user': (0, User_1.UserToJSON)(value['user']),
        'workspaces': (value['workspaces'].map(WorkspaceWithCustomer_1.WorkspaceWithCustomerToJSON)),
    };
}
