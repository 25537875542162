"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
/* tslint:disable */
/* eslint-disable */
__exportStar(require("./AddressesApi"), exports);
__exportStar(require("./AuthApi"), exports);
__exportStar(require("./ChatbotsApi"), exports);
__exportStar(require("./DevopsApi"), exports);
__exportStar(require("./DoctorsApi"), exports);
__exportStar(require("./DocumentApi"), exports);
__exportStar(require("./EmailingApi"), exports);
__exportStar(require("./EventsApi"), exports);
__exportStar(require("./InvitationsApi"), exports);
__exportStar(require("./MedicalHistoriesApi"), exports);
__exportStar(require("./MedicalRecordsApi"), exports);
__exportStar(require("./OthersApi"), exports);
__exportStar(require("./PatientsApi"), exports);
__exportStar(require("./PaymentsApi"), exports);
__exportStar(require("./PdfApi"), exports);
__exportStar(require("./ProfessionalsApi"), exports);
__exportStar(require("./ReferentialsApi"), exports);
__exportStar(require("./ReportFeedbacksApi"), exports);
__exportStar(require("./ReportSnapshotsApi"), exports);
__exportStar(require("./ReportsApi"), exports);
__exportStar(require("./ReportsProcessingApi"), exports);
__exportStar(require("./SecretariesApi"), exports);
__exportStar(require("./StoragesApi"), exports);
__exportStar(require("./StudentsApi"), exports);
__exportStar(require("./TemplateReportsApi"), exports);
__exportStar(require("./TranscriptionsApi"), exports);
__exportStar(require("./UsersApi"), exports);
__exportStar(require("./WebsocketApi"), exports);
__exportStar(require("./WorkspacesApi"), exports);
